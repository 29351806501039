<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
    }
  },
  created: function () {
  },
  mounted: function(){
  },
  methods: {
  },
}
</script>
<style>
</style>