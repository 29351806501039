<template>
<Modal v-model="showModal" :mask-closable="false" draggable width="400px">
  <div slot="header">
    指令询问窗口
  </div>
  <div class="cust-list">
    {{cmdAskModalTopic}} - {{cmdAskModalContent}}
  </div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
  </div>
</Modal>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'ModalCommandAsk',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      curitem: 'all',
      tabHead: {
        name: '名称',
        ts: '时间',
        send: '方向',
        content: '内容'
      }
    }
  },
  computed: {
    ...mapState('cmd', ['cmdAskModalRefresh', 'cmdAskModalTopic', 'cmdAskModalContent']),
  },
  watch: {
    value(newVal) {
    // console.log('modal data send rec1')
      this.showModal = newVal;
    },
    showModal(val){
    // console.log('modal data send rec2')
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    // console.log('modal data send rec')
  },
  destroyed: function(){
  },
  methods: {
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.cust-list{
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
}
.cust-item{
  border: solid 1px rgba(128, 128, 128, 0.274);
  margin: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.item-header{
  height: 20px;
  width: 100%;
  display: flex;
}
.item-name{
  width: 100px;
  flex: auto;
  margin-right: 10px;
  /* border-right: solid 1px gray; */
  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.item-time{
  width: 170px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-type{
  width: 30px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-content{
  width: 100%;
  border-top: solid 1px rgba(128, 128, 128, 0.274);
  padding-top: 5px;
  /* white-space: nowrap; */
}
.cust-item:hover{
  border: solid 1px blue;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>