<template>
<Modal v-model="showModal" :mask-closable="false" width="1200px">
  <div slot="header">
    指令询问窗口
  </div>
  <div class="cust-list">
    <div class="cmd-list">
      <div :class="['cmd-item', item.id == curId ? 'cmd-select':'']" v-for="(item, index) in cmdAskModalContent" :key="index" @click="showCmdContent(item)">{{item.start}}</div>
    </div>
    <div class="cmd-content">
      <u-table :data="list" style="width: 100%;" row-key="id" border stripe :height="598" default-expand-all>
        <u-table-column type="index">
        </u-table-column>
        <!-- <u-table-column prop="id" type="selection">
        </u-table-column> -->
        <u-table-column prop="name" label="指令名称" sortable width="200">
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="channels" label="控制站点" sortable width="130">
          <template slot-scope="params">
            {{params.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="channels" label="所在支路" sortable width="130">
          <template slot-scope="params">
            {{params.row.paramName}}
          </template>
        </u-table-column>
        <u-table-column prop="channels" label="控制内容">
          <template slot-scope="params">
            {{getCmdArgName(params.row.args)}}
          </template>
        </u-table-column>
        <!-- <u-table-column prop="result" label="执行结果">
          <template slot-scope="params">
            {{params.row.result}}
          </template>
        </u-table-column> -->
      </u-table>
    </div>
  </div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="primary" style="margin-right: 8px" @click="deal(true)">允许发送</Button>
    <Button type="error" style="margin-right: 8px" @click="deal(false)">取消发送</Button>
  </div>
</Modal>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'ModalCommandAskList',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      curitem: 'all',
      tabHead: {
        name: '名称',
        ts: '时间',
        send: '方向',
        content: '内容'
      },
      curId: '',
      list: [],
    }
  },
  computed: {
    ...mapState('cmd', ['cmdAskModalRefresh', 'cmdAskModalTopic', 'cmdAskModalContent']),
  },
  watch: {
    value(newVal) {
    // console.log('modal data send rec1')
      this.showModal = newVal;
      if(this.showModal){
        this.initList();
      }
    },
    showModal(val){
    // console.log('modal data send rec2')
      this.$emit('showChanged', val);
    },
    cmdAskModalRefresh(){
      this.initList();
    }
  },
  mounted: function(){
    // console.log('modal data send rec')
  },
  destroyed: function(){
  },
  methods: {
    initList: function(){
      if(this.cmdAskModalContent.length > 0){
        this.showCmdContent(this.cmdAskModalContent[0]);
      }else{
        this.curId = '';
      this.$set(this, 'list', []);
      }
    },
    getCmdArgName: function(args){
      return `通道 C${args.channel} ${args.open ? '通电' : '断电'} 至 ${args.expire}`
    },
    showCmdContent: function(item){
      this.curId = item.id;
      this.$set(this, 'list', item.list);
    },
    deal: function(send){
      if(this.curId == ''){
        this.$Message.warning('请选择要处理的指令');
        return;
      }
      this.$store.dispatch('cmd/dealWaitCmds', {cmdId: this.curId, send}).then(res => {
        if(res.code == 0){
          this.$Message.info(`指令已${send?'发送':'取消'}`);
        }else{
          // this.$Message.error(res.data);
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.cust-list{
  /* border: solid 1px red; */
  display: flex;
  height: 600px;
}
.cmd-list{
  border: solid 1px rgba(170, 168, 168, 0.596);
  width: 250px;
  flex: none;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.cmd-select{
  border: solid 1px blue !important;
}
.cmd-item{
  height: 50px;
  line-height: 50px;
  margin: 3px 5px;
  font-size: 20px;
  text-align: center;
  flex: none;
  border: solid 1px rgba(134, 133, 133, 0.445);
  cursor: pointer;
}
.cmd-item:hover{
  border: solid 1px red;
}
.cmd-content{
  border: solid 1px rgba(170, 168, 168, 0.596);
  width: 500px;
  flex: auto;
}
.cust-item{
  border: solid 1px rgba(128, 128, 128, 0.274);
  margin: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.item-header{
  height: 20px;
  width: 100%;
  display: flex;
}
.item-name{
  width: 100px;
  flex: auto;
  margin-right: 10px;
  /* border-right: solid 1px gray; */
  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.item-time{
  width: 170px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-type{
  width: 30px;
  flex: none;
  /* border-right: solid 1px gray; */
}
.item-content{
  width: 100%;
  border-top: solid 1px rgba(128, 128, 128, 0.274);
  padding-top: 5px;
  /* white-space: nowrap; */
}
.cust-item:hover{
  border: solid 1px blue;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>